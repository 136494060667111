.whatis{
    width:100%;
    height:100%;
    color:white;
    margin-top:-300px;
    background-image:url("../../public/background-section1.png");
    background-size: cover;
    background-position: top center;

    &__content{

        &-container{
        padding:9%;
        display:flex;
        justify-content:space-between;
        padding-top:25%;

        }
        
        &-left{
        width:50%;
        }

        &-right{
            margin-top:-7%;
        }

        &-h2{
            font-family: "Saira";
            color: rgba(243, 255, 228, 1);
            font-size: 3vw;
            font-weight: 400;
        }

        &-p{
           font-family: "Open Sans";
           font-size:14px;
           line-height:26px;
           letter-spacing: 0.09em;
           color:white;

           &-container{
            padding-left:3em;
            padding-top:1em;
            border-left:5px solid #39FDFD;
           }
        }
    }
}

.button{
    padding:1rem 3rem;
    background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
    border-radius: 12px;
    text-transform: uppercase;
    color:#0E185F;
    font-family: "Open Sans";
    font-weight:700;
    font-size:14px;
    margin:2rem 0rem;
    transition: all 250ms ease-in-out;

    &:hover{
        cursor:pointer;
	box-shadow: 0 10px 38px rgba(130, 237, 223, 0.8)  ; 
	-webkit-box-shadow: 0 10px 38px rgba(130, 237, 223, 0.8)  ; 
	-moz-box-shadow: 0 10px 38px rgba(130, 237, 223, 0.8)  ; 
    transform: scale(1.1);
    }
}



#sections-bg{
    background-image:url("../../public/home-bg.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
}

    //TABLET

    @media (max-width: 1000px) {
        .whatis{
            width:100%;
            background-repeat: no-repeat;
            background-size: 300%;
            background-position:top;
            height:100%;
            color:white;
            margin-top:-32%;
        
            &__content{
        
                &-container{
                padding:9%;
                display:flex;
                flex-direction:column;
                justify-content:space-between;
                padding-top:25%;
                }

                &-left{
                    width:100%;
                    }
            
                    &-right{
                        margin-top:0;
    
                        img{
                            width:100%;
                            
                        }
                    }
            }
        }

    }
    
    
    //MOBILE
    
    @media (max-width: 530px) {

        .whatis{
            width:100%;
            background-repeat: no-repeat;
            background-size: 300%;
            background-position:top;
            height:100%;
            color:white;
            margin-top:-32%;
        
            &__content{
        
                &-container{
                padding:9%;
                display:flex;
                flex-direction:column;
                justify-content:space-between;
                padding-top:25%;
             
        
                }
                
                &-left{
                width:100%;
                }
        
                &-right{
                    margin-top:0;

                    img{
                        width:100%;
                        
                    }
                }
        
                &-h2{
                    font-family: "Saira";
                    color: rgba(243, 255, 228, 1);
                    font-size: 1.5rem;
                    font-weight: 400;
                    text-align:left;
                    margin-top:20px;
                    margin-left:25px;
                }
        
                &-p{
                   font-family: "Open Sans";
                   font-size:.7rem;
                   line-height:26px;
                   letter-spacing: 0.09em;
                   color:white;
        
                   &-container{
                    padding-left:1.2em;
                    padding-top:.8em;
                    height:22em;
                    margin:10px;
                    border-left:5px solid #39FDFD;
                   }
                }
            }
        }
        
        .button{
            padding:1rem 3rem;
            background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
            border-radius: 12px;
            text-transform: uppercase;
            color:#0E185F;
            font-family: "Open Sans";
            font-weight:700;
            font-size:14px;
            margin:0 20px;
            transition: all 250ms ease-in-out;
        
            &:hover{
                cursor:pointer;
            box-shadow: 0 10px 38px rgba(130, 237, 223, 0.8)  ; 
            -webkit-box-shadow: 0 10px 38px rgba(130, 237, 223, 0.8)  ; 
            -moz-box-shadow: 0 10px 38px rgba(130, 237, 223, 0.8)  ; 
            transform: scale(1.1);
            }
        }

     }