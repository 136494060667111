.menu{
    width:100%;
    height:100%;
    color:white;
    background-color: rgb(0,0,0,.7);
    position:absolute;
    top:0;
    left:0;

    &-pages{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:15em;

        ul{
            li{
                text-decoration: none;
                list-style: none;
                text-align:center;
                margin-bottom:4rem;
                a{
                    font-size:1.5rem;
                    text-align:center;
                    font-weight: 400;
                    letter-spacing: 0.09em;
                    color: #FFFFFF;
                    font-family: 'Open Sans';
                    text-transform: uppercase;

                }
            }
        }
    }

    &-socials{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap:.6em;
        text-align:center;
        margin-top:5em;
        bottom:0;

        svg{
            width:50%;
            height:50%;
            text-align:center;
        }
        img{
            width:50%;
            height:50%;
        }
    }

}