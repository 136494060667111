.blog {
    background-image:url("../../../public/blog_background.png");
    background-color:transparent;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size:cover;
    font-family: "Open Sans"
}

.post_title{
    color:black;
    font-weight:600;
    font-size:2em;
    border-left: 5px solid #10034f;
    padding: .2em .5em;
    margin-top:1em;
    
}

.post_description{
    font-size:1em;
    color:gray;
    padding: 1em 0;
}

.post_card{
    width:50em;
    height:100%;
    background-color:white;
    padding: 2em;
    border-radius:10px;

    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}

.posts_container{
    display:flex;
    width:100%;
    gap:1em;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height:100%;
    padding-top:15em;
}

img{
    border-radius:10px;
}

.post_image{
    padding: 1em 0;
    img{
    width:100%;
    height:300px;
    object-fit:cover;
    border-radius:10px;
    }
}


.container_date{
    display:flex;
    width:100%;
    justify-content: right;
    gap: 1em;
    color:gray;
}

p{
    margin-bottom:.8em;
}


.signup{
    height:100vh;
    &_widget{
        display: flex;
        align-items:center;
        justify-content:center;
    }
}

.signup_widget iframe{
    margin-top:5em;
    width: 100%;
    height: 60vh; /* Adjust this value according to your needs */
    min-height: 150px; /* Adjust this value according to your needs */
    max-height: 500px; /* Adjust this value according to your needs */
}

//MOBILE

@media (max-width: 535px) {

    .post_card{
        width:25em;
        height:100%;
        background-color:white;
        padding: 2em;
        border-radius:10px;
    
        box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
                  0 2px 2px rgba(0,0,0,0.12), 
                  0 4px 4px rgba(0,0,0,0.12), 
                  0 8px 8px rgba(0,0,0,0.12),
                  0 16px 16px rgba(0,0,0,0.12);
    }

}