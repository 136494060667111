.header{
    background-image:url("../../public/background-header.png");
    width:100%;
    height:70em;
    background-repeat: no-repeat;
    background-size:cover;
    background-attachment: fixed;
    background-position: center;
}

.heroText{
    margin-left: 9%;
    width: 50%;
    padding-top: 15em;
    color:#0E185F;
    h1{
        font-family: "Saira";
        font-size: 3vw;
        font-weight: 500;
        line-height: 1em;
        margin-bottom: .4em;
    }
    p{
        font-family: "Open sans";
        font-size: 2rem;
        line-height: 2em;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #0E185F;
    }
    .botonesHero{
        margin-top: 3em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
        .btn{
            font-family: "Saira";
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 900;
            padding: 1.5em 3.5em;
            border-radius: 12px;
            transition: all 200ms ease;
            &:hover{
                transform: scale(1.1);
            }
        }
        .btn1{
            background:  linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #D9FF99 77.47%);
          

            &:hover{
             cursor:pointer;
             box-shadow: 0 10px 38px rgba(101, 250, 170, 0.8)  ; 
             -webkit-box-shadow: 0 10px 38px rgba(101, 250, 170, 0.8)  ; 
             -moz-box-shadow: 0 10px 38px rgba(101, 250, 170, 0.8)  ; 
             transform: scale(1.1);
            }
        }
        .btn2{
            margin-left: 3.5em;
            border: 3px solid rgba(14, 24, 95, 1);
        }
    }
}
.refere{
    display:none;
}


//TABLET

@media (max-width: 1000px) {

    .heroText{
        width: 100%;
        margin-left:0;
        padding-left:9%;

        h1{
            font-size: 4rem;
            
        }
  }
}

//MOBILE

@media (max-width: 530px) {

    .refere{
        width:100%;
        position:absolute;
        top:0;
        left:0;
        opacity:.3;
        display:block;
        z-index:99;
    }

    .header{
        background-image:url("../../public/background-header.png");
        width:100%;
        height:90vh;
        background-repeat: no-repeat;
        background-size:cover;
        background-attachment: fixed;
        background-position: center;
    }

    .heroText{
        margin-left: 0;       
        width: 100%;          
        color:#0E185F;
        text-align:center; 
        padding: 10.5em 5%;
        margin-left:0;

        h1{
            font-family: "Saira";
            font-size: 1.95rem;         
            font-weight: 500;
            line-height: 1.2em;
            margin-bottom: .6em;    
        }
        p{
            font-family: "Open sans";
            font-size: .9rem;    
            font-weight: 600;
            line-height: 1em;
            width:20%;
            display:inline;
        }
        .botonesHero{
            margin-top: 1em;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;       
            gap:20px;
             
            .btn{
                font-family: "Saira";
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 900;
                padding: .8em 3em;
                border-radius: 12px;
                transition: all 200ms ease;
                &:hover{
                    transform: scale(.95);
                }
            }
            .btn1{
                background:  linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #D9FF99 77.47%);
              
    
                &:hover{
                 box-shadow:2px 2px 10px 1px #72FFB9;
                }
            }
            .btn2{
                margin-left: 0;     /////
                border: 2px solid rgba(14, 24, 95, 1);
            }
        }
    }
    

}

