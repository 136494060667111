.nolarchives{
    padding:0 8%;
    width:100%;
    height:50em;
    background-color:black;
    background: url("../../public/bg-game2.png");
    background-size:cover;
    background-position:bottom;
    background-repeat: no-repeat;

    &_container{
        display:flex;
        gap:100px;
        align-items:center;
        justify-content:space-between;
        padding-top:10em;
    }

    &_left{
        width:50%;
    }

    &_right{
        width:50%;
    }
}


//TABLET

@media (max-width: 1000px) {
    .nolarchives{
        padding:3em 9%;
        width:100%;
        height:50em;
        background-color:black;
        background: url("../../public/bg-game2.png");
        background-size:cover;
        background-repeat: no-repeat;
        background-position:bottom;
    
        &_container{
            display:flex;
            gap:100px;
            align-items:center;
            justify-content:space-between;
        }
    
        &_left{
            width:50%;
            display:none;
        }
    
        &_right{
            width:100%;

        }
    }
}

//MOBILE

@media (max-width: 530px) {

    .nolarchives{
        padding:0 9%;
        width:100vw;
        height:50em;
        background-color:black;
        background: url("../../public/bg-game2.png");
        background-size:cover;
        background-repeat: no-repeat;
        background-position:left;
    
        &_container{
            display:flex;
            gap:100px;
            align-items:center;
            justify-content:space-between;
        }
    
        &_left{
            width:50%;
            display:none;
        }
    
        &_right{
            width:100%;

            div p{
                opacity:1;
                color:white;
            }

        }
    }

    .buttonuniverse{
        margin-top:2em;
        a{
            color:#0e185f;
        }
    }

}

