.universe-title{
    width:100%;
    height:25em;
    background-image:url("../../../public/boidverse-bg.png");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: bottom;
    display:flex;
    align-items:end;
    padding:2em 9%;

    h1{
        font-family: 'Saira';
        font-style: normal;
        font-weight: 700;
        font-size: 4rem;
        line-height: 81px;
        color: #FFFFFF;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    }

}

.colUniv{
    width:50%;
}

.titleBoid{
font-family: 'Saira';
font-style: normal;
font-weight: 500 !important;
font-size: 3em !important;
line-height: 81px !important;
color: #F3FFE4 !important;
}


.universe{
    background-image:url("../../../public/universe-bg.png") !important;
    width:100%;
    height:100% !important;
    background-size:cover;
    background-repeat: no-repeat;
}


.mineables{
    width:100%;
    height:auto;
    background-color:black;
    background-image:url("../../../public/crystal-bg.png");
    display:flex;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: contain;


    h1{
        padding-top:10rem;
        
    }

   .minerals-container{
       display:flex;
       flex-wrap:wrap;
       align-items: center;
       justify-content: center;
       width:70%;
   }

    .slide-item{
            display: flex;
            align-items: center;
            justify-content: end;
            flex-direction: column;
            gap:2rem;
            background: linear-gradient(180deg, rgba(48, 78, 228, 0.3) 0%, #000000 100%);
            border-radius: 20.989px;
            width:13vw;
            height:12em;
            background-position: bottom;
            padding-bottom:2em;
            margin: 1em;
            margin-top: 10em;
            transition: all 250ms ease-in-out;
            text-align:center;

            img{
                transition: all 250ms ease-in-out;
                width:80%;
            }

            &:hover{
                transform: translateY(-30px);
                img{
                    transform: scale(1.2);

                }
            }

            h2{
                font-weight: 700;
                font-size: 1.5rem;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color:white;

            }
        }
    }

    .titanium{
        background: linear-gradient(180deg, rgba(226, 226, 226, 0.57) 0%, #000000 100%) !important;
    }

    .gold{
        background: linear-gradient(180deg, #FFFB7B 0%, #000000 100%) !important;
    }

    .boidcrystal{
        background: linear-gradient(180deg, rgba(48, 228, 228, 0.3) 0%, #000000 100%) !important;
    }

    .water{
        background: linear-gradient(180deg, #84D0F7 0%, #000000 100%) !important;
    }

    .chemicals{  
        background: linear-gradient(180deg, #A8F74A 0%, #000000 100%) !important;
    }

    .steel{
        background: linear-gradient(180deg, #FFFFFF 0%, #000000 100%) !important;
    }

    .aluminium{
        background: linear-gradient(180deg, #A2A2A2 0%, #000000 100%) !important;
    }

    .fuel{
        background: linear-gradient(180deg, #FFB36D 0%, #000000 100%) !important;
    }

    .unrefined-oil{
        background: linear-gradient(180deg, #C8605D 0%, #000000 100%) !important;
    }

    .food{
        background: linear-gradient(180deg, #FF805E 0%, #000000 100%) !important;
    }

    .research{
        background: linear-gradient(180deg, #513BD4 0%, #000000 100%) !important;
    }




    .buildings_container{
    img{
        width:100%;
    }
}

    #rarities{
        height: 20em;
        width: 100%;
        background-color:#000312;
        text-align: center;
        padding: 3em 0;
        background-color:black;
        h2{
            color: #F3FFE4;
            font-family: "Saira";
            font-weight: 500;
            font-size: 2.5rem;
        }
        .rarity{
            width: 90%;
            background: linear-gradient(180deg, rgba(150, 150, 150, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 1em 0;
            border-radius: 20px;
            margin-top: 3em;
            h3{
                font-family: "Saira";
                font-weight: 400;
            }
            .trash{
                color: #828282;
            }
            .common{
                color: #705746;
            }
            .regular{
                color: #9E7320;
            }
            .uncommon{
                color: #3849E1;
            }
            .rare{
                color: #52CF3E;
            }
            .premium{
                color: #3BD7CD;
            }
            .epic{
                color: #C624E0;
            }
            .legendary{
                color: #E3AF28;
            }
            .mythic{
                color: #E73737;
            }
        }
    }

    #exchange{
        background-color: black;
        height: 50em;
        width: 100%;
        display: flex;
        align-items: center;

        .contenedorExchange{
            width: 85%;
            margin: auto;
            
            p{
                margin: 1em 0 2em;  
            }
            .exchangeImgs{
                display: flex;
                justify-content: space-between;
                gap:1em;
                img{
                    border-radius: 20px;
                    width:50%;
                    
                }
            }
        }
    }


    .pBoid{
        font-size: 1rem;
        color: #F3FFE4;
        line-height: 1.5em;
        font-family: "Open Sans";
        font-weight: 300;
        letter-spacing: 0.09em;
    }



    #playerCards{
        height: 80em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:black;
        .contenedorTodoNft{
            width: 85%;
            .contenedorNft{
                display: flex;
                justify-content: center;
                gap: 10%;
                .nftText{
                    width:60%;
                    h2{
                        font-family: "Saira";
                        font-size: 2rem;
                        color: #fff;
                    }
                    ul{
                        font-family: "Open Sans";
                        margin: 1em 0 2em;
                        li{
                            color: #F3FFE4;
                            line-height: 1.5em;
                        }
                    }
                    p{
                        font-family: "Open Sans";
                        margin: 2em 0 2em;
                        color: #F3FFE4;  
                        line-height: 1.5em; 
                    }
                }

                .nftImg{
                    
                    img{
                        width:100%;
                    }
                }
            }
            h3{
                font-family: "Saira";
                font-size: 1.2rem;
                color: #fff;
            }
            .treesText{
                font-family: "Open Sans";
                margin: 1em 0 2em;
                color: #F3FFE4;
                line-height: 1.5em;
            }
        }
        
    }


    #exploration{
        height: 30em;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: black;
        .contenedorExploration{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10%;
            width: 80%;
            .explorationImg{
                img{
                    width:100%;
                    border-radius: 25px;
                }
            }
            .explorationText{
                width:50%;
                h2{
                    font-family: "Saira";
                    font-size: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
                p{
                    font-family: "Open Sans";
                    margin: 2em 0 2em;
                    color: #F3FFE4;  
                    line-height: 1.5em; 
                }
            }
        }
    }



    #combat{
        height: 60em;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: black;
        .contenedorCombat{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10%;
            width: 80%;
            .combatImg{
                img{
                    width:100%;
                    border-radius: 25px;
                }
            }
            .combatText{
                width:50%;
                h2{
                    font-family: "Saira";
                    font-size: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
                p{
                    font-family: "Open Sans";
                    margin: 2em 0 2em;
                    color: #F3FFE4;  
                    line-height: 1.5em; 
                }
            }
        }
    }


    #war{
        background-color: black;
        height: 60em;
        width: 100%;
        display: flex;
        align-items: center;
        .contenedorWar{
            width: 85%;
            margin: auto;
            text-align: center;
            h2{
                font-family: "Saira";
                font-size: 2rem;
                color: #fff;
                margin-bottom: 1em;
            }
            p{
                font-family: "Open Sans";
                margin: 0 auto;
                color: #F3FFE4;    
                width: 70%;
                line-height: 1.5em; 
            }
            .warImgs{
                display: flex;
                justify-content: space-evenly;
                margin-top: 5em;
                img{
                    border-radius: 20px;
                    width: 30em;
                }
            }
        }
    }


    
//TABLET

@media (max-width: 1000px) {

    #playerCards{
        height: 80em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:black;
        .contenedorTodoNft{
            width: 85%;
            .contenedorNft{
                display: flex;
                justify-content: center;
                gap: 10%;
                flex-direction: column;
                .nftText{
                    width:100%;
                    h2{
                        font-family: "Saira";
                        font-size: 2rem;
                        color: #fff;
                    }
                    ul{
                        font-family: "Open Sans";
                        margin: 1em 0 2em;
                        li{
                            color: #F3FFE4;
                            line-height: 1.5em;
                        }
                    }
                    p{
                        font-family: "Open Sans";
                        margin: 2em 0 2em;
                        color: #F3FFE4;  
                        line-height: 1.5em; 
                    }
                }

                .nftImg{
                    width:100%;
                    display: flex;
                    justify-content: center;
                    img{
                        width:70%;
                        
                    }
                }
            }
            h3{
                font-family: "Saira";
                font-size: 1.2rem;
                color: #fff;
            }
            .treesText{
                font-family: "Open Sans";
                margin: 1em 0 2em;
                color: #F3FFE4;
                line-height: 1.5em;
            }
        }
        
    }


    #exploration{
        height: 70em;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: black;
        .contenedorExploration{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column-reverse;
            gap: 5%;
            width: 80%;
            .explorationImg{
                width:100%;
                display: flex;
                justify-content: center;
                img{
                    width:70%;
                    border-radius: 25px;
                }
            }
            .explorationText{
                width:100%;
                h2{
                    font-family: "Saira";
                    font-size: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
                p{
                    font-family: "Open Sans";
                    margin: 2em 0 2em;
                    color: #F3FFE4;  
                    line-height: 1.5em; 
                }
            }
        }
    }


    
    #combat{
        height: 80em;
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: black;
        .contenedorCombat{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 5%;
            width: 80%;
            .combatImg{
                display: flex;
                justify-content: center;
                img{
                    width:70%;
                    border-radius: 25px;
                }
            }
            .combatText{
                width:100%;
                h2{
                    font-family: "Saira";
                    font-size: 2rem;
                    color: #fff;
                    font-weight: 500;
                }
                p{
                    font-family: "Open Sans";
                    margin: 2em 0 2em;
                    color: #F3FFE4;  
                    line-height: 1.5em; 
                }
            }
        }
    }

    #war{
        background-color: black;
        height: 100em;
        width: 100%;
        display: flex;
        align-items: center;
        .contenedorWar{
            width: 85%;
            margin: auto;
            text-align: center;
            h2{
                font-family: "Saira";
                font-size: 2rem;
                color: #fff;
                margin-bottom: 1em;
            }
            p{
                font-family: "Open Sans";
                margin: 0 auto;
                color: #F3FFE4;    
                width: 100%;
                line-height: 1.5em; 
            }
            .warImgs{
                display: flex;
                flex-wrap:wrap;
                justify-content: space-evenly;
                margin-top: 5em;
                img{
                    border-radius: 20px;
                    width: 30em;
                }
            }
        }
    }

.colUniv{
    width:100%;
}

.buildings_container{
    padding-top:0;
    margin-top:0;
    img{
        width:100%;

    }
}


.mineables{
    height:auto;
}

.mineables{
.minerals-container{
    display:flex;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    width:100%;
}

  .slide-item{
            display: flex;
            align-items: center;
            justify-content: end;
            flex-direction: column;
            gap:2rem;
            background: linear-gradient(180deg, rgba(48, 78, 228, 0.3) 0%, #000000 100%);
            border-radius: 20.989px;
            width:20em;
            height:20em;
            background-position: bottom;
            padding-bottom:2em;
            margin: 1em;
            margin-top: 10em;
            transition: all 250ms ease-in-out;
            text-align:center;

            img{
                transition: all 250ms ease-in-out;
                width:80%;
            }

            &:hover{
                transform: translateY(-30px);
                img{
                    transform: scale(1.2);

                }
            }

            h2{
                font-weight: 700;
                font-size: 1.5rem;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color:white;

            }

    
                h2{
                    font-weight: 700;
                    font-size: 1.5rem;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    color:white;
    
                }
            }

        }
        }
 




//MOBILE

@media (max-width: 535px) {


    #rarities{
        height:auto;
    }
    .rarity{
        flex-direction: column;
        height:auto;
        margin-top:0;
        width:30px;
    }

    #war{
        background-color: black;
        height: 100em;
        width: 100%;
        display: flex;
        align-items: center;
        .contenedorWar{
            width: 85%;
            margin: auto;
            text-align: center;
            h2{
                font-family: "Saira";
                font-size: 2rem;
                color: #fff;
                margin-bottom: 1em;
            }
            p{
                font-family: "Open Sans";
                margin: 0 auto;
                color: #F3FFE4;    
                width: 100%;
                line-height: 1.5em; 
            }
            .warImgs{
                display: flex;
                flex-wrap:wrap;
                justify-content: space-evenly;
                margin-top: 5em;
                img{
                    border-radius: 20px;
                    width: 100%;
                }
            }
        }
    }


    .minerals-container{
        margin:0;
        padding:0;
        width:100%;
    }

    #playerCards{
        height:100%;
    }

    #exploration{
        height:100%;
    }

    #combat{
        height:100%;
    }

    .titleBoid{
        font-size: 2rem !important;
        line-height: 2rem !important;
        margin-top:2em;
        text-align:center;
        padding-bottom:2em;
    }


    .minerals-container{
    .slide-item{
        display: flex;
        align-items: center;
        justify-content: end !important;
        flex-direction: column;
        gap:2rem;
        background: linear-gradient(180deg, rgba(48, 78, 228, 0.3) 0%, #000000 100%);
        border-radius: 20.989px;
        width:40%;
        height:10em;
        background-position: bottom;
        padding:0;
        margin: .5em;
        margin-bottom:8em;

        h2{
            font-size:1em;
            margin-bottom:1em;
        }
        }
    }


    #exchange{
        height:auto;
    }

    .exchangeImgs{
        flex-direction: column;
        width:100%;
        align-items: center;

        img{
            width:100% !important;
        }
    }

}
