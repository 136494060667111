.boidvoid{
    background-image: url("../../../public/boidvoid_bg.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    

&_header{
    width:100vw;
    height:100%;
    background-repeat: no-repeat;
    background-size:cover;
}

&_button{

    &-container{
        width:100vw;
        height:100vh;
        padding: 0 9%;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction: column;
        gap:1em;

        img{
            width:80%;
        }
    }
}

&_info{
    
    &-container{
        padding:1em 9%;
        margin-top:30em;
        height:20em;
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:100%;

        &-left{
            width:45%;
        }

        &-right{
            width:50%;
        }
    }
}
}


.longinfo{
    width:100vw;
    background-color:#00000B;
    height:200vh;
}


#boidVoid{
    height: 100%;
    width: 100%;
    .contenedorBoid{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 0 auto;
        gap: 5em;
        padding-top: 10em;
        .colBoid{
            .rowBoid{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 2em;
                .planet{
                    width: 4em;
                    img{
                        height: 3em;
                        margin-right: 1.5em;
                    }
                } 
                .textBoid{
                    h2{
                        font-family: "Saira";
                        font-weight: 700;
                        color: #F3FFE4;
                        font-size: 1rem;
                        margin-bottom: .8em;
                        font-family: 'Saira';
                        letter-spacing: 0.15em;
                        color: #F3FFE4;

                    }
                    p{
                        font-family: "Open Sans";
                        font-size: 1rem;
                        color: #F3FFE4;
                        line-height: 1.5em;
                        font-family: 'Open Sans';
                        font-weight: 300;
                        letter-spacing: 0.09em;
                    }
                }
            }
        }
    }
}



.boidvoid_slider{
    padding: 0 9%;
    display: flex;
    align-items: center;
    margin-top:10%;

    &-container{
        width:100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(27px);
        border-radius: 24px;
        padding:2em;
        display:flex;

        &_left{
            width:30%;
        }

        &_right{
            width:100%;
            border-left: 1px solid rgb(255,255,255,.5);
            display:flex;
            align-items: center;
            justify-content: left;
            padding: 5em;

            h1{
                font-family: 'Saira';
                font-style: normal;
                font-weight: 700;
                font-size: 2rem;
                letter-spacing: 0.15em;
                color: #F3FFE4;
            }

            p{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 1rem;
                letter-spacing: 0.09em;
                color: #FFFFFF;
            }

            img{
                width:100%;
            }
        }
    }

    h2{
        font-family: 'Saira';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.15em;
        color: #717171;
        padding:.7em 0;
        transition: all 250ms linear;
        cursor:pointer;

        &:hover{
            color:white;
        }
    }
}



.flex-title{
    display: flex;
    align-items: center;
    gap:1em;

    img{
        width:15%;
    }
}



//TABLET

@media (max-width: 1000px) {

    .boidvoid{
        background-image: url("../../../public/boidvoid_bg.jpg");
        background-repeat: no-repeat;
        background-size:cover;

        &_header{
            width:100vw;
            height:100%;
        }
        
        &_button{
        
            &-container{
                width:100%;
                height:100vh;
                padding:9%;
                display:flex;
                align-items:center;
                justify-content:center;

                img{
                    width:70%;
                }
            }
        }
        
        &_info{
            
            &-container{
                padding:5% 9%;
                display:flex;
                justify-content:space-between;
                align-items:center;
                flex-direction: column;
                width:100%;
                
        
                &-left{
                    width:100%;
                }
        
                &-right{
                    width:100%;
                    img{
                        width:20em;
                        display:none;
                    }
                }
            }
        }
        }
        
        
        .longinfo{
            width:100vw;
            background-color:#00000B;
            height:200vh;
        }
        


    #boidVoid{
        height: 100%;
        width: 100%;
        background-image:url("");
        background-repeat: no-repeat;
        .contenedorBoid{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 0 auto;
            gap: 5em;
            padding-top: 10em;

        }
    }

}


@media (max-width: 535px) {
    
    #boidVoid{
        height: 100%;
        width: 100%;
        background-image:url("");
        background-repeat: no-repeat;
        .contenedorBoid{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 0 auto;
            gap: 5em;
            padding-top: 5em;

        }
    }

 .boidvoid{
    background-image: url("../../../public/boidvoid_bg.jpg");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: top;

        &_header{
            width:100vw;
            height:30em;
        }
        
        &_button{
        
            &-container{
                width:100%;
                height:100vh;
                padding:9%;
                display:flex;
                align-items:center;
                justify-content:center;
                gap:3em;
                

                img{
                    width:100%;
                }
            }
        }
        
        &_info{
            
            &-container{
                padding:5% 9%;
                display:flex;
                justify-content:space-between;
                align-items:center;
                flex-direction: column;
                width:100%;
                height:30em;
                margin:0;

        
                &-left{
                    width:100%;

                    &-p{
                        font-size:1rem;
                    }
                }
        
                &-right{
                    display:none;
                
                }
            }
        }
        }



        .boidvoid_slider{
            padding: 0 9%;
            height:90em;
            margin:0;
        
            &-container{
                width:100%;
                background-color: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(27px);
                border-radius: 24px;
                padding:2em;
                display:flex;
                flex-direction: column;
        
                &_left{
                    width:100%;
                }
        
                &_right{
                    width:100%;
                    border-top: 1px solid rgb(255,255,255,.5);
                    border-left: none;
                    display:flex;
                    align-items: center;
                    justify-content: left;
                    padding: 4em 0;
        
                    h1{
                        font-family: 'Saira';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 2rem;
                        letter-spacing: 0.15em;
                        color: #F3FFE4;
                    }
        
                    p{
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1rem;
                        letter-spacing: 0.09em;
                        color: #FFFFFF;
        
                    }
                }
            }
        
            h2{
                font-family: 'Saira';
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
                letter-spacing: 0.15em;
                color: #717171;
                padding:.7em 0;
                transition: all 250ms linear;
                cursor:pointer;
        
                &:hover{
                    color:white;
                }
            }
        }
        

    }



    //MOBILE

@media (max-width: 535px) {
    .boidvoid{
        background-image: url("../../../public/boidvoid_bg.jpg");
        background-repeat: no-repeat;
        background-size:cover;

        &_header{
            width:100vw;
            height:80em;
        }
        
        &_button{
        
            &-container{
                width:100%;
                height:100vh;
                padding:9%;
                display:flex;
                align-items:center;
                justify-content:center;

                img{
                    width:70%;
                }
            }
        }
        
        &_info{
            
            &-container{
                padding:0 9%;
                display:flex;
                justify-content:space-between;
                align-items:center;
                flex-direction: column;
                width:100%;
        
                &-left{
                    width:100%;
                }
        
                &-right{
                    width:100%;
                    img{
                        width:20em;
                    }
                }
            }
        }
        }
        
        
        .longinfo{
            width:100vw;
            background-color:#00000B;
            height:200vh;
        }
        


    #boidVoid{
        height: 100%;
        width: 100%;
        background-image:url("");
        background-repeat: no-repeat;
        .contenedorBoid{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin: 0 auto;
            gap: 5em;
            padding-top: 10em;

        }
    }


    .border{
        height:90%;
    }

}