* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  
.sections{
  background-image:url("../../../public/backgroundfinal.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.sections2{
  
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

  body {
    background-color: #294892;
    font-family: 'Open Sans';
  }
  
  .open-nav {
    -webkit-transform: translateY(0);
            transform: translateY(0);
            
  }
  
  header {
    position: fixed;
    z-index: 999;
    height: 5em;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 8%;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(51.6%, #000000), color-stop(69.68%, rgba(0, 0, 0, 0.764)), color-stop(85.64%, rgba(0, 0, 0, 0.41)), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, #000000 51.6%, rgba(0, 0, 0, 0.764) 69.68%, rgba(0, 0, 0, 0.41) 85.64%, rgba(0, 0, 0, 0) 100%);
  }
  
  header .logo {
    text-decoration: none;
    color: #fff;
    font-family: 'Comfortaa', cursive;
    font-size: 1.5rem;
    font-weight: 300;
  }
  
  header .social-links {
    display: none;
  }
  
  header div {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: black;
    width: 100%;
    z-index: 100;
    text-transform: uppercase;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: -webkit-transform .5s ease-in;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in, -webkit-transform .5s ease-in;
  }
  
  header div .close {
    width: 1.5em;
    float: right;
    margin: 2em 2.1em;
  }
  
  header div .close path {
    fill: #fff;
    width: 18px;
  }
  
  header div .menuAvatar {
    width: 29px;
    position:relative;
  }
  
  header div ul {
    margin-top: 10em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style: none;
  }
  
  header div ul a {
    color: #fff;
    text-decoration: none;
    padding: 1.5em 1em;
    display: block;
  }
  
  main {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    padding: 5em 8% 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 2em;
    position: relative;
  }
  
  main .main-text {
    text-align: center;
  }
  
  main .main-text h1 {
    font-size: 2.5rem;
    font-family: 'Saira';
    color: #fff;
  }
  
  main .main-text p {
    font-size: 14px;
    font-family: "Saira";
    line-height: 26px;
    color: #fff;
  }
  
  main .main-text .main-button {
    display: inline-block;
    margin-top: 2em;
    padding: .8em 2em;
    background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 18px #40F9FF;
            box-shadow: 0px 0px 18px #40F9FF;
  }
  
  main .main-text .main-button a {
    color: #000;
    text-decoration: none;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
  }
  
  main .main-img {
    width: 100%;
    max-width: 20em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  main .main-img img {
    width: 100%;
  }
  
  #intro {
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50em;
    width: 100%;
    padding: 0 8%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 2em;
    position: relative;
    z-index: 90;
  }
  
  #intro .intro-text {
    text-align: right;
  }
  
  #intro .intro-text h3 {
    color: #00FFDD;
    font-family: 'Saira';
    font-size: 20px;
  }
  
  #intro .intro-text h2 {
    font-size: 2rem;
    line-height: 3rem;
    font-family: 'Saira';
    color: #fff;
    margin-bottom: 1em;
  }
  
  #intro .intro-text p {
    font-size: 12px;
    font-family: "Saira";
    line-height: 18px;
    font-weight: 400;
    color: #fff;
  }
  
  #intro .intro-img {
    width: 100%;
    max-width: 20em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  #intro .intro-img img {
    width: 100%;
  }
  
  #ukraine {
   
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 50em;
    width: 100%;
    padding: 0 8%;
    color: #fff;
    z-index: 100;
  }
  
  #ukraine .flex-ukraine {
    padding-top: 4em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  #ukraine .flex-ukraine .ukraine-text .subtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  #ukraine .flex-ukraine .ukraine-text .subtitle h3 {
    font-family: "Saira";
    font-weight: 500;
    font-size: 18px;
  }
  
  #ukraine .flex-ukraine .ukraine-text .subtitle img {
    width: 3em;
  }
  
  #ukraine .flex-ukraine .ukraine-text h2 {
    font-family: "Saira";
    font-weight: 700;
    font-size: 37px;
  }
  
  #ukraine .flex-ukraine .ukraine-text p {
    font-family: "Saira";
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
  
  #ukraine .flex-ukraine .ukraine-card {
    width: 60%;
  }
  
  #ukraine .flex-ukraine .ukraine-card img {
    width: 100%;
  }
  
  #ukraine .flex-ukraine .ukraine-avatar {
    width: 100%;
  }
  
  #work {
  
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 75em;
    width: 100%;
    padding: 0 8%;
    color: #fff;
    padding-top: 4em;
  }
  
  #work .work-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 246, 255, 0.3)), to(#000000));
    background: linear-gradient(180deg, rgba(106, 246, 255, 0.3) 0%, #000000 100%);
    border-radius: 15px;
    padding: 2em 2em;
  }
  
  #work .work-container .work-text h3 {
    font-family: "Saira";
    font-weight: 500;
    font-size: 18px;
    color: #00FFDD;
  }
  
  #work .work-container .work-text h2 {
    font-family: "Saira";
    font-weight: 700;
    font-size: 37px;
    line-height: 45px;
    margin-bottom: 1em;
  }
  
  #work .work-container .work-text li {
    font-family: "Saira";
    font-weight: 400;
    font-size: 14px;
    margin-bottom: .5em;
  }
  
  #work .work-container .work-text .main-button {
    display: inline-block;
    margin-top: 2em;
    padding: .8em 2em;
    background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 18px #40F9FF;
            box-shadow: 0px 0px 18px #40F9FF;
  }
  
  #work .work-container .work-text .main-button a {
    color: #000;
    text-decoration: none;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
  }
  
  #work .work-container .work-img {
    width: 100%;
    margin-top: 3em;
  }
  
  #work .work-container .work-img img {
    width: 100%;
  }
  
  #work .work-container .work-img2 {
    display: none;
  }
  
  #parts {
    
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
    padding: 0 8%;
    color: #fff;
    padding-top: 4em;

  }
  
  #parts .parts-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 255, 139, 0.3)), to(#000000));
    background: linear-gradient(180deg, rgba(106, 255, 139, 0.3) 0%, #000000 100%);
    border-radius: 15px;
    padding: 2em 2em;
  }
  
  #parts .parts-container .parts-text h3 {
    font-family: "Saira";
    font-weight: 500;
    font-size: 18px;
    color: #00FFDD;
  }
  
  #parts .parts-container .parts-text h2 {
    font-family: "Saira";
    font-weight: 700;
    font-size: 37px;
    line-height: 45px;
    margin-bottom: 1em;
  }
  
  #parts .parts-container .parts-text p {
    font-family: "Saira";
    font-weight: 400;
    font-size: 14px;
    margin-bottom: .5em;
  }
  
  #parts .parts-container .parts-img {
    width: 100%;
    margin-top: 3em;
  }
  
  #parts .parts-container .parts-img img {
    width: 100%;
  }
  
  #parts .parts-container .parts-img2 {
    display: none;
  }
  
  #limited {
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 65em;
    width: 100%;
    padding: 0 8%;
    color: #fff;
    padding-top: 4em;
  }
  
  #limited .limited-text {
    text-align: center;
  }
  
  #limited .limited-text h2 {
    font-family: "Saira";
    font-weight: 700;
    font-size: 37px;
    margin-bottom: .5em;
  }
  
  #limited .limited-text p {
    font-family: "Saira";
    font-weight: 400;
    font-size: 12px;
  }
  
  #limited .limited-text .main-button {
    display: inline-block;
    margin: 2em 0;
    padding: .8em 2em;
    background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
    border-radius: 12px;
    -webkit-box-shadow: 0px 0px 18px #40F9FF;
            box-shadow: 0px 0px 18px #40F9FF;
  }
  
  #limited .limited-text .main-button a {
    color: #000;
    text-decoration: none;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 14px;
  }
  
  #limited .limited-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: .5em;
  }
  
  #limited .limited-container .limited-item {
    width: 9em;
  }
  
  #limited .limited-container .limited-item img {
    width: 100%;
  }
  
  #artist {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto !important;
    width: 100%;
    padding: 0 8%;
    padding-top: 4em;
    display:flex;
    flex-direction: column;
  }
  
  #artist .intro-text {
    text-align: right;
  }
  
  #artist .intro-text h3 {
    color: #00FFDD;
    font-family: 'Saira';
    font-size: 20px;
  }
  
  #artist .intro-text h2 {
    font-size: 2rem;
    line-height: 3rem;
    font-family: 'Saira';
    color: #fff;
    margin-bottom: 1em;
  }
  
  #artist .intro-text p {
    font-size: 12px;
    font-family: "Saira";
    line-height: 18px;
    font-weight: 400;
    color: #fff;
  }
  
  #artist .intro-text .links {
    width: 100%;
  }
  
  #artist .intro-text .links a {
    width: 100%;
    font-size: 12px;
    font-family: "Saira";
    line-height: 18px;
    font-weight: 400;
    color: #00FFDD;
  }

#artist .image img{
  max-width:80%;
}

#artist .image{
  display:flex;
  justify-content:center;
  width:100%;
}

  #airdrop {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto !important;
    width: 100%;
    padding: 0 8%;
    padding-top: 4em;
  }
  
  #airdrop .intro-text {
    text-align: left;
  }
  
  #airdrop .intro-text h3 {
    color: #00FFDD;
    font-family: 'Saira';
    font-size: 20px;
  }
  
  #airdrop .intro-text h2 {
    font-size: 2rem;
    line-height: 3rem;
    font-family: 'Saira';
    color: #fff;
    margin-bottom: 1em;
  }
  
  #airdrop .intro-text p {
    font-size: 12px;
    font-family: "Saira";
    line-height: 18px;
    font-weight: 400;
    color: #fff;
  }
  
  #airdrop .intro-text a {
    color: #00FFDD;
  }

  #airdrop .drops{
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
    padding:5em;
    flex-wrap:wrap;
    align-items:center;
    gap:3em;
   
  }

  #airdrop .drops .drops-item :hover{
    animation: float 1s ease-in-out infinite;
    cursor:pointer;
  }

  #blank {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto !important;
    width: 100%;
    padding: 0 8%;
    padding-top: 4em;
  }

  @keyframes float {
    0% {
      box-shadow: 0px 0px 39px #48FAF4;
      transform: translatey(0px);
      transform:scale(1);
      transition: all 250ms linear;
    }
    50% {
      box-shadow: 0px 0px 39px #48FAF4;
      transform: translatey(-20px);
      transform:scale(1.1);
      transition: all 250ms linear;
    }
    100% {
      box-shadow: 0px 0px 39px #48FAF4;
      transform: translatey(0px);
      transform:scale(1);
      transition: all 250ms linear;
    }
  }
  
  @media only screen and (min-width: 768px) {
    header {
      position: fixed;
      z-index: 999;
      height: 8em;
      width: 100vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0 8%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(51.6%, #000000), color-stop(69.68%, rgba(0, 0, 0, 0.764)), color-stop(85.64%, rgba(0, 0, 0, 0.41)), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(180deg, #000000 51.6%, rgba(0, 0, 0, 0.764) 69.68%, rgba(0, 0, 0, 0.41) 85.64%, rgba(0, 0, 0, 0) 100%);
    }
    header .logo {
      text-decoration: none;
      color: #fff;
      font-family: 'Comfortaa', cursive;
      font-size: 2rem;
      font-weight: 300;
    }
    header .social-links {
      display: none;
    }
    header div {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      background-color: #000;
      width: 100%;
      z-index: 100;
      text-transform: uppercase;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transition: -webkit-transform .5s ease-in;
      transition: -webkit-transform .5s ease-in;
      transition: transform .5s ease-in;
      transition: transform .5s ease-in, -webkit-transform .5s ease-in;
    }
    header div .close {
      width: 2em;
      float: right;
      margin: 2em 2.1em;
    }
    header div .close path {
      fill: #fff;
    }
    header div ul {
      margin-top: 15em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      list-style: none;
    }
    header div ul a {
      font-size: 1.5rem;
      color: #fff;
      text-decoration: none;
      padding: 1.5em 0;
      display: block;
    }
    main {
      
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
      width: 100%;
      padding: 5em 8% 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 4em;
      position: relative;
    }
    main .main-text {
      text-align: center;
      width: 90%;
    }
    main .main-text h1 {
      font-size: 3rem;
      font-family: 'Saira';
      color: #fff;
    }
    main .main-text p {
      font-size: 18px;
      font-family: "Saira";
      line-height: 30px;
      color: #fff;
    }
    main .main-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: .8em 2em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    main .main-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 14px;
    }
    main .main-img {
      width: 100%;
      max-width: 25em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    main .main-img img {
      width: 100%;
    }
    #intro {
     
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 50em;
      width: 100%;
      padding: 0 8%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      gap: 2em;
      position: relative;
      z-index: 90;
    }
    #intro .intro-text {
      text-align: right;
    }
    #intro .intro-text h3 {
      color: #00FFDD;
      font-family: 'Saira';
      font-size: 25px;
    }
    #intro .intro-text h2 {
      font-size: 2.5rem;
      line-height: 3rem;
      font-family: 'Saira';
      color: #fff;
      margin-bottom: 1em;
    }
    #intro .intro-text p {
      font-size: 18px;
      font-family: "Saira";
      line-height: 25px;
      font-weight: 400;
      color: #fff;
    }
    #intro .intro-img {
      width: 100%;
      max-width: 25em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    #intro .intro-img img {
      width: 100%;
    }
    #ukraine {
   
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 50em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      z-index: 100;
    }
    #ukraine .flex-ukraine {
      padding-top: 4em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 25px;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle img {
      width: 4em;
    }
    #ukraine .flex-ukraine .ukraine-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 2.5rem;
    }
    #ukraine .flex-ukraine .ukraine-text p {
      font-family: "Saira";
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
    }
    #ukraine .flex-ukraine .ukraine-card {
      width: 40%;
    }
    #ukraine .flex-ukraine .ukraine-card img {
      width: 100%;
    }
    #ukraine .flex-ukraine .ukraine-avatar {
      width: 100%;
    }

    #ukraine .ukraine-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: .8em 2em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 18px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }

    #work {
     
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      height: 65em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      padding-top: 4em;
    }
    #work .work-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 246, 255, 0.3)), to(#000000));
      background: linear-gradient(180deg, rgba(106, 246, 255, 0.3) 0%, #000000 100%);
      border-radius: 15px;
      padding: 2em 2em;
    }
    #work .work-container .work-text h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 18px;
      color: #00FFDD;
    }
    #work .work-container .work-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 37px;
      line-height: 45px;
      margin-bottom: 1em;
    }
    #work .work-container .work-text li {
      font-family: "Saira";
      font-weight: 400;
      font-size: 18px;
      margin-bottom: .5em;
    }
    #work .work-container .work-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: .8em 2em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    #work .work-container .work-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 14px;
    }
    #work .work-container .work-img {
      display: none;
    }
    #work .work-container .work-img2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 3em;
    }
    #work .work-container .work-img2 img {
      width: 100%;
    }
    #parts {
      
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      height: 50em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      padding-top: 4em;
    }
    #parts .parts-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 255, 139, 0.3)), to(#000000));
      background: linear-gradient(180deg, rgba(106, 255, 139, 0.3) 0%, #000000 100%);
      border-radius: 15px;
      padding: 2em 2em;
    }
    #parts .parts-container .parts-text h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 18px;
      color: #00FFDD;
    }
    #parts .parts-container .parts-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 37px;
      line-height: 45px;
      margin-bottom: 1em;
    }
    #parts .parts-container .parts-text p {
      font-family: "Saira";
      font-weight: 400;
      font-size: 18px;
      margin-bottom: .5em;
    }
    #parts .parts-container .parts-img {
      display: none;
    }
    #parts .parts-container .parts-img2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 3em;
    }
    #parts .parts-container .parts-img2 img {
      width: 100%;
    }
    #limited {
      
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 50em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      padding-top: 4em;
    }
    #limited .limited-text {
      text-align: center;
    }
    #limited .limited-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 2.5rem;
      margin-bottom: .5em;
    }
    #limited .limited-text p {
      font-family: "Saira";
      font-weight: 400;
      font-size: 18px;
    }
    #limited .limited-text .main-button {
      display: inline-block;
      margin: 2em 0;
      padding: .8em 2em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    #limited .limited-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 14px;
    }
    #limited .limited-container {
      margin-top: 3em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      gap: 1em;
    }
    #limited .limited-container .limited-item {
      width: 12em;
    }
    #limited .limited-container .limited-item img {
      width: 100%;
    }
    #artist {
      
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 30em;
      width: 100%;
      padding: 0 8%;
      padding-top: 4em;
    }
    #artist .intro-text {
      text-align: right;
      width:50%;
    }
    #artist .intro-text h3 {
      color: #00FFDD;
      font-family: 'Saira';
      font-size: 20px;
    }
    #artist .intro-text h2 {
      font-size: 2.5rem;
      line-height: 3rem;
      font-family: 'Saira';
      color: #fff;
      margin-bottom: 1em;
    }
    #artist .intro-text p {
      font-size: 18px;
      font-family: "Saira";
      line-height: 25px;
      font-weight: 400;
      color: #fff;
    }
    #artist .intro-text .links {
      width: 100%;
    }
    #artist .intro-text .links a {
      width: 100%;
      font-size: 18px;
      font-family: "Saira";
      line-height: 25px;
      font-weight: 400;
      color: #00FFDD;
    }
    #airdrop {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 20em;
      width: 100%;
      padding: 0 8%;
      padding-top: 4em;
    }
    #airdrop .intro-text {
      text-align: left;
    }
    #airdrop .intro-text h3 {
      color: #00FFDD;
      font-family: 'Saira';
      font-size: 20px;
    }
    #airdrop .intro-text h2 {
      font-size: 2.5rem;
      line-height: 3rem;
      font-family: 'Saira';
      color: #fff;
      margin-bottom: 1em;
    }
    #airdrop .intro-text p {
      font-size: 18px;
      font-family: "Saira";
      line-height: 18px;
      font-weight: 400;
      color: #fff;
    }
    #airdrop .intro-text a {
      color: #00FFDD;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    header {
      position: fixed;
      z-index: 999;
      height: 8em;
      width: 100vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0 8%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(51.6%, #000000), color-stop(69.68%, rgba(0, 0, 0, 0.764)), color-stop(85.64%, rgba(0, 0, 0, 0.41)), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(180deg, #000000 51.6%, rgba(0, 0, 0, 0.764) 69.68%, rgba(0, 0, 0, 0.41) 85.64%, rgba(0, 0, 0, 0) 100%);
    }
    header .logo {
      text-decoration: none;
      color: #fff;
      font-family: 'Comfortaa', cursive;
      font-size: 2rem;
      font-weight: 300;
    }
    header .social-links {
      display: none;
    }
    header div {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      background-color: #000;
      width: 100%;
      z-index: 100;
      text-transform: uppercase;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transition: -webkit-transform .5s ease-in;
      transition: -webkit-transform .5s ease-in;
      transition: transform .5s ease-in;
      transition: transform .5s ease-in, -webkit-transform .5s ease-in;
    }
    header div .close {
      width: 2em;
      float: right;
      margin: 2em 2.5em;
    }
    header div .close path {
      fill: #fff;
    }
    header div ul {
      margin-top: 12em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      list-style: none;
    }
    header div ul a {
      font-size: 1rem;
      color: #fff;
      text-decoration: none;
      padding: 1.5em 0;
      display: block;
    }
    main {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }
    main .main-text {
      text-align: left;
    }
    #intro {
      
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 40em;
      width: 100%;
      padding: 0 8%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 2em;
      position: relative;
      z-index: 90;
    }
    #intro .intro-text {
      text-align: right;
      width: 60%;
    }
    #intro .intro-text h3 {
      color: #00FFDD;
      font-family: 'Saira';
      font-size: 25px;
    }
    #intro .intro-text h2 {
      font-size: 2.5rem;
      line-height: 3rem;
      font-family: 'Saira';
      color: #fff;
      margin-bottom: 1em;
    }
    #intro .intro-text p {
      font-size: 16px;
      font-family: "Saira";
      line-height: 25px;
      font-weight: 400;
      color: #fff;
    }
    #intro .intro-img {
      width: 100%;
      max-width: 25em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    #intro .intro-img img {
      width: 100%;
    }
    #ukraine {
   
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 40em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      z-index: 100;
    }
    #ukraine .flex-ukraine {
      padding-top: 4em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 100%;
      gap: 2em;
    }
    #ukraine .flex-ukraine .ukraine-text {
      width: 100%;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 25px;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle img {
      width: 4em;
    }
    #ukraine .flex-ukraine .ukraine-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 2.5rem;
    }
    #ukraine .flex-ukraine .ukraine-text p {
      font-family: "Saira";
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
    }
    #ukraine .flex-ukraine .ukraine-card {
      width: 50%;
    }
    #ukraine .flex-ukraine .ukraine-card img {
      width: 100%;
    }
    #ukraine .flex-ukraine .ukraine-avatar {
      width: 100%;
    }
    #work {
      
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      height: 65em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      padding-top: 4em;
    }
    #work .work-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 246, 255, 0.3)), to(#000000));
      background: linear-gradient(180deg, rgba(106, 246, 255, 0.3) 0%, #000000 100%);
      border-radius: 15px;
      padding: 2em 3em;
    }
    #work .work-container .work-text h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 18px;
      color: #00FFDD;
    }
    #work .work-container .work-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 37px;
      line-height: 45px;
      margin-bottom: 1em;
    }
    #work .work-container .work-text li {
      font-family: "Saira";
      font-weight: 400;
      font-size: 16px;
      margin-bottom: .5em;
    }
    #work .work-container .work-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: .8em 2em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    #work .work-container .work-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 14px;
    }
    #work .work-container .work-img {
      display: none;
    }
    #work .work-container .work-img2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 3em;
    }
    #work .work-container .work-img2 img {
      max-width: 50em;
      margin: 0 auto;
    }
    #parts .parts-container {
      padding: 2em 3em;
    }
    #parts .parts-container .parts-text p {
      font-size: 16px;
    }
    #parts .parts-container .parts-img2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 3em;
    }
    #parts .parts-container .parts-img2 img {
      max-width: 50em;
      margin: 0 auto;
    }
   
    #limited .limited-text {
      width: 70%;
      margin: 0 auto;
    }
    #limited .limited-text p {
      font-size: 16px;
    }

    #artist{
      display:flex;
      flex-direction: row-reverse;
      height:auto;
    }

    #artist .intro-text p {
      font-size: 16px;
    }
    #artist .intro-text .links a {
      font-size: 16px;
    }
    #airdrop .intro-text p {
      font-size: 16px;
    }
  }
  
  @media only screen and (min-width: 1700px) {
    header {
      position: fixed;
      z-index: 999;
      height: 9em;
      width: 100vw;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0 8%;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(51.6%, #000000), color-stop(69.68%, rgba(0, 0, 0, 0.764)), color-stop(85.64%, rgba(0, 0, 0, 0.41)), to(rgba(0, 0, 0, 0)));
      background: linear-gradient(180deg, #000000 51.6%, rgba(0, 0, 0, 0.764) 69.68%, rgba(0, 0, 0, 0.41) 85.64%, rgba(0, 0, 0, 0) 100%);
    }
    header .logo {
      text-decoration: none;
      color: #fff;
      font-family: 'Comfortaa', cursive;
      font-size: 2.5rem;
      font-weight: 300;
    }
    header .social-links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    header .social-links svg {
      margin: 0 .5em;
      cursor: pointer;
      -webkit-transition: -webkit-transform 200ms ease;
      transition: -webkit-transform 200ms ease;
      transition: transform 200ms ease;
      transition: transform 200ms ease, -webkit-transform 200ms ease;
    }
    header .social-links svg:hover {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
    header div {
      position: relative;
      height: 0;
      background-color: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      margin-left: 12%;
      width: 100%;
      z-index: 100;
      text-transform: uppercase;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transition: -webkit-transform .5s ease-in;
      transition: -webkit-transform .5s ease-in;
      transition: transform .5s ease-in;
      transition: transform .5s ease-in, -webkit-transform .5s ease-in;
    }
    header div .close {
      width: 2em;
      float: right;
      margin: 2em 2.5em;
      display: none;
    }
    header div .close path {
      fill: #fff;
    }
    header div ul {
      margin-top: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      gap: 3.5em;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      list-style: none;
    }
    header div ul a {
      font-size: 1rem;
      color: #fff;
      text-align:center;
      text-decoration: none;
      display: inline-block;
      position: relative;
      width:7em;
    }
    header div ul a::after {
      content: "";
      position: absolute;
      background-color: #fff;
      width: 0;
      height: 3px;
      left: 0;
      bottom: 12px;
      -webkit-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
    }
    header div ul a:hover::after {
      width: 100%;
    }
    header div ul a:hover {
      color: #fff;
    }
    header .menuAvatar {
      display: none;
    }
    main {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
    }
    main .main-text {
      text-align: left;
      width: 40%;
    }
    main .main-text h1 {
      font-size: 4rem;
    }
    main .main-text p {
      font-size: 20px;
      line-height: 2rem;
    }
    main .main-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: 1.3em 3em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    main .main-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 20px;
    }
    main .main-img {
      width: 100%;
      max-width: 40em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    main .main-img img {
      width: 100%;
    }
    #intro {
      
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 50em;
      width: 100%;
      padding: 0 8%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      gap: 2em;
      position: relative;
      z-index: 90;
    }
    #intro .intro-text {
      text-align: right;
      width: 60%;
    }
    #intro .intro-text h3 {
      color: #00FFDD;
      font-family: 'Saira';
      font-size: 30px;
      margin-bottom: 1em;
    }
    #intro .intro-text h2 {
      font-size: 65px;
      line-height: 3rem;
      font-family: 'Saira';
      color: #fff;
      margin-bottom: 1em;
    }
    #intro .intro-text p {
      font-size: 16px;
      font-family: "Saira";
      line-height: 30px;
      font-weight: 400;
      color: #fff;
    }
    #intro .intro-img {
      width: 100%;
      max-width: 40em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    #intro .intro-img img {
      width: 100%;
    }
    #ukraine {
      
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 50em;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      z-index: 100;
    }
    #ukraine .flex-ukraine {
      padding-top: 4em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 100%;
      gap: 15em;
    }
    #ukraine .flex-ukraine .ukraine-text {
      width: 80%;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 32px;
    }
    #ukraine .flex-ukraine .ukraine-text .subtitle img {
      width: 5em;
    }
    #ukraine .flex-ukraine .ukraine-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 65px;
    }
    #ukraine .flex-ukraine .ukraine-text p {
      font-family: "Saira";
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }
    #ukraine .flex-ukraine .ukraine-card {
      width: 30%;
    }
    #ukraine .flex-ukraine .ukraine-card img {
      width: 100%;
    }
    #ukraine .flex-ukraine .ukraine-avatar {
      width: 100%;
    }
    #work {
      
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      height: auto;
      width: 100%;
      padding: 0 8%;
      color: #fff;
      padding-top: 4em;
    }
    #work .work-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 246, 255, 0.3)), to(#000000));
      background: linear-gradient(180deg, rgba(106, 246, 255, 0.3) 0%, #000000 100%);
      border-radius: 15px;
      padding: 4em 3em;
    }
    #work .work-container .work-text {
      width: 100%;
    }
    #work .work-container .work-text h3 {
      font-family: "Saira";
      font-weight: 500;
      font-size: 18px;
      color: #00FFDD;
    }
    #work .work-container .work-text h2 {
      font-family: "Saira";
      font-weight: 700;
      font-size: 37px;
      line-height: 45px;
      margin-bottom: 1em;
    }
    #work .work-container .work-text li {
      font-family: "Saira";
      font-weight: 400;
      font-size: 16px;
      margin-bottom: .5em;
    }
    #work .work-container .work-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: .8em 2em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    #work .work-container .work-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 14px;
    }
    #work .work-container .work-img {
      display: none;
    }
    #work .work-container .work-img2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 0;
    }
    #work .work-container .work-img2 img {
      max-width: 40em;
      margin: auto;
    }

    #parts{
      height:auto;
    }
    #parts .parts-container {
      padding: 4em 3em;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      gap: 5em;
      margin-top: 5em;
    }
    #parts .parts-container .parts-text p {
      font-size: 16px;
      line-height: 30px;
    }
    #parts .parts-container .parts-img2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-top: 3em;
    }
    #parts .parts-container .parts-img2 img {
      max-width: 50em;
      margin: 0 auto;
    }
    #limited {
      height: auto;
    }
    #limited .limited-text {
      width: 70%;
      margin: 0 auto;
    }
    #limited .limited-text h2 {
      font-size: 65px;
    }
    #limited .limited-text p {
      font-size: 18px;
    }
    #limited .limited-text .main-button {
      display: inline-block;
      margin-top: 2em;
      padding: 1.5em 3.5em;
      background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
      border-radius: 12px;
      -webkit-box-shadow: 0px 0px 18px #40F9FF;
              box-shadow: 0px 0px 18px #40F9FF;
    }
    #limited .limited-text .main-button a {
      color: #000;
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: 700;
      font-size: 20px;
    }
    #limited .limited-container {
      margin-top: 3em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      gap: 1em;
    }
    #limited .limited-container .limited-item {
      width: 15em;
    }
    #limited .limited-container .limited-item img {
      width: 100%;
    }
    #artist {
      height: auto;
      display:flex;
      flex-direction: row-reverse;
      padding-top:10em;
    }

    #artist .intro-text {
      width: 50%;
      margin-left: auto;
    }
    #artist .intro-text h3 {
      font-size: 30px;
      margin-bottom: 1em;
    }
    #artist .intro-text h2 {
      font-size: 65px;
    }
    #artist .intro-text p {
      font-size: 18px;
    }
    #artist .intro-text .links a {
      font-size: 18px;
    }
    #airdrop {
      height: auto;
    }
    #airdrop .intro-text h3 {
      font-size: 30px;
      margin-bottom: 1em;
    }
    #airdrop .intro-text h2 {
      font-size: 65px;
    }
    #airdrop .intro-text p {
      font-size: 18px;
    }
  }
  /*# sourceMappingURL=estilos.css.map */