
    nav{
        height: 11em;
        width:100%;
        background: linear-gradient(180deg, #F4FFE3 0%, rgba(255, 255, 255, 0) 87.43%);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 9vw;
        position:absolute;
        z-index:999;
        

        .logo {
            a {
                img {
                    max-width: 250px;
                    height: auto;
                }
            }
        }
        .navbar{
            flex: 1;
            margin-left: 6em;
            ul{
                list-style: none;
                
                li{
                    display: inline-block;
                    padding-right: 3.2em;
                    a{
                        text-decoration: none;
                        font-family: "Open sans";
                        font-weight: 600;
                        text-transform: uppercase;
                        transition: all 200ms ease;
                        letter-spacing: 0.09em;

                        &:hover{
                            color: rgb(121, 121, 121);
                        }
                    }
                }
            }
        }
        .socialLinks{
            a{
                padding-left: 1.2em;

                svg{
                transition: all 200ms ease; 
                }
                svg:hover{
                    transform: scale(1.2);
                }
            }
        }
    }

    .hamburger{
        display:none;
    }

    .cross{
        display:none;
        z-index:999;
    }

    .logo{
        z-index:999;
    }


    //TABLET

@media (max-width: 1000px) {

    nav{
        position:fixed;
    }
    .hamburger{
        display:block;

        svg{
        width: 2em;
        height: 2em;
        }
    }

    .navbar{
        display:none;
    }

    .socialLinks{
        display:none;
    }
}


    //MOBILE

    @media (max-width: 530px) {


        nav{
            height: 6em;
            width:100%;
            background: linear-gradient(180deg, #F4FFE3 0%, rgba(255, 255, 255, 0) 87.43%);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 9vw;
            position:fixed;
            
    
            .logo a{
                font-family: "Comfortaa";
                font-size: 1.6rem;
                text-decoration: none;
            }
            .navbar{
                flex: 1;
                margin-left: 6em;
                ul{
                    list-style: none;
                    
                    li{
                        display: inline-block;
                        padding-right: 3.2em;
                        a{
                            text-decoration: none;
                            font-family: "Open sans";
                            font-weight: 600;
                            text-transform: uppercase;
                            transition: all 200ms ease;
                            letter-spacing: 0.09em;
    
                            &:hover{
                                color: rgb(121, 121, 121);
                            }
                        }
                    }
                }
            }
            .socialLinks{
                img{
                    padding-left: 1.2em;
                    transition: all 200ms ease; 
                    &:hover{
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
