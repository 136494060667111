footer{
    height: 25em;
    width: 100%;
    padding-top: 6em;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10em;
    .footerLogo {
        a {
            img {
                max-width: 250px;
                height: auto;
            }
        }
    }
    .footerAbout{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h4{
            text-decoration: none;
            color: #fff;
            font-family: "Saira";
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 1em;
        }
        a{
            text-decoration: none;
            color: #fff;
            opacity: .5;
            font-family: "Open Sans";
            font-weight: 300;
            margin-bottom: .5em;
        }
    }
    .footerSocials{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h4{
            text-decoration: none;
            color: #fff;
            font-family: "Saira";
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 1em;
        }
        a{
            text-decoration: none;
            color: #fff;
            opacity: .5;
            font-family: "Open Sans";
            font-weight: 300;
            margin-bottom: .5em;
        }
    }
    .footerOthers{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        h4{
            text-decoration: none;
            color: #fff;
            font-family: "Saira";
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 1em;
        }
        a{
            text-decoration: none;
            color: #fff;
            opacity: .5;
            font-family: "Open Sans";
            font-weight: 300;
        }
    }
}


.subscribe{
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    height:100%;
    margin:auto;
}

.mj-embedded{
    margin:auto;
}

//TABLET

@media (max-width: 1000px) {

}

//MOBILE

@media (max-width: 1000px) {

    footer{
        height: 100%;
        width: 100%;
        padding-top: 3em;
        padding-bottom: 3em;
        background: linear-gradient(0deg, rgba(65, 250, 255, 0.2) 0, rgba(255, 255, 255, 0) 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4em;
        
        

        .footerLogo a{
            text-decoration: none;
            color: #fff;
            font-family: "Comfortaa";
            font-size: 2rem;
           
        }
        .footerAbout{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h4{
                text-decoration: none;
                color: #fff;
                font-family: "Saira";
                font-size: 1.2rem;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 1em;
            }
            a{
                text-decoration: none;
                color: #fff;
                opacity: .5;
                font-family: "Open Sans";
                font-weight: 300;
                margin-bottom: .5em;
            }
        }
        .footerSocials{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h4{
                text-decoration: none;
                color: #fff;
                font-family: "Saira";
                font-size: 1.2rem;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 1em;
            }
            a{
                text-decoration: none;
                color: #fff;
                opacity: .5;
                font-family: "Open Sans";
                font-weight: 300;
                margin-bottom: .5em;
            }
        }
        .footerOthers{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h4{
                text-decoration: none;
                color: #fff;
                font-family: "Saira";
                font-size: 1.2rem;
                text-transform: uppercase;
                font-weight: 500;
                margin-bottom: 1em;
            }
            a{
                text-decoration: none;
                color: #fff;
                opacity: .5;
                font-family: "Open Sans";
                font-weight: 300;
            }
        }
    }
}