#theGame{
    height: 100%;
    position: relative;
    padding-bottom:5%;
    background-image:url("../../public/background-section2.png");
    background-size: cover;
    background-position: top center;
    .refe2{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: .2;
    }
    .theGame{
        margin: 0 auto;
        width: 50%;
        padding-top: 7.2em;
        .about{
            font-family: "Saira";
            color: rgba(243, 255, 228, 1);
            text-align: center;
            font-size: 1.3rem;
        }
        h2{
            font-family: "Saira";
            color: rgba(243, 255, 228, 1);
            text-align: center;
            font-size: 3vw;
            font-weight: 400;
            margin-bottom: .2em;
        }
        p{
            font-family: "Open Sans";
           font-size:14px;
           line-height:26px;
           opacity:70%;
           letter-spacing: 0.09em;
           color:white;
           text-align:center;
        }
        .btnGame{
            display: flex;
            justify-content: center;
            align-items: center;
            gap:2em;
            text-align:center;

            .btn-right{
                background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #D9FF99 77.47%);
                padding: 1em 4em;
            }
        }
        a{
            display: inline-block;
            padding: 1em 4em;
            background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
            border-radius: 10px;
            margin-top: 1.5em;
            text-decoration: none;
            text-transform: uppercase;
            font-family: "Open sans";
            font-weight: 800;
            transition: all 200ms ease;
            &:hover{
                cursor:pointer;
             box-shadow: 0 10px 38px rgba(101, 250, 170, 0.8)  ; 
             -webkit-box-shadow: 0 10px 38px rgba(101, 250, 170, 0.8)  ; 
             -moz-box-shadow: 0 10px 38px rgba(101, 250, 170, 0.8)  ; 
             transform: scale(1.1);
            }
        }
    }
    .cards{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        gap: 2em;
        margin-top: 6em;
        .card{
            height: 33em;
            width: 25vw;    
            background: linear-gradient(182.13deg, #001AB5 1.79%, #000000 31.79%);
            box-shadow: 0px 0px 111.644px rgba(0, 0, 0, 0.08);
            padding: 1em;
            border-radius: 20px;
            img{
                border-radius: 10px;
                width:100%;
            }
            h3{
                text-align: center;
                font-family: "Open Sans";
                color: #fff;
                margin: .5em 0;
                font-weight:400;
                letter-spacing: 0.09em;
            }
            p{
                font-family: "Open sans";
                color: #fff;
                line-height: 1.8em;
                text-align: center;
                padding: 0 1em;
                opacity: .5;
            }
            
        }
    }
}



//TABLET
    
@media (max-width: 1000px) {
    #theGame{
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding-bottom:5%;
        .refe2{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: .2;
        }
        .theGame{
            margin: 0 auto;
            width: 35%;
            padding-top: 7.2em;
            width:70%;
            .about{
                font-family: "Saira";
                color: rgba(243, 255, 228, 1);
                text-align: center;
                font-size: 1.3rem;
            }
            h2{
                font-family: "Saira";
                color: rgba(243, 255, 228, 1);
                text-align: center;
                font-size: 3.9rem;
                font-weight: 400;
                margin-bottom: .2em;
            }
            p{
                font-family: "Open Sans";
               font-size:14px;
               line-height:26px;
               opacity:70%;
               letter-spacing: 0.09em;
               color:white;
               text-align:center;
            }
            .btnGame{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap:.4rem;
            }
            a{
                display: inline-block;
                padding: 1em 4em;
                background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
                border-radius: 10px;
                margin-top: 1.5em;
                text-decoration: none;
                text-transform: uppercase;
                font-family: "Open sans";
                font-weight: 800;
                transition: all 200ms ease;
                &:hover{
                    box-shadow: 0px 0px 20px 1px #72FFB9;
                    transform: scale(.9);
                }
            }
        }
        .cards{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            gap: 2em;
            margin-top: 6em;
            flex-direction: column;
            .card{
                height: 33em;
                width: 28em;
                background: linear-gradient(182.13deg, #001AB5 1.79%, #000000 31.79%);
                box-shadow: 0px 0px 111.644px rgba(0, 0, 0, 0.08);
                padding: 1em;
                border-radius: 20px;
                img{
                    border-radius: 10px;
                }
                h3{
                    text-align: center;
                    font-family: "Open Sans";
                    color: #fff;
                    margin: .5em 0;
                    font-weight:400;
                    letter-spacing: 0.09em;
                }
                p{
                    font-family: "Open sans";
                    color: #fff;
                    line-height: 1.8em;
                    text-align: center;
                    padding: 0 1em;
                    opacity: .5;
                }
                
            }
        }
    }
    
}



//MOBILE
    
@media (max-width: 530px) {

    #theGame{
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding-bottom:5%;
        .refe2{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: .2;
        }
        .theGame{
            margin: 0 auto;
            width: 100%;
            padding: 0 9%;
            padding-top: 3.5em;
            .about{
                font-family: "Saira";
                color: rgba(243, 255, 228, 1);
                text-align: center;
                font-size: 1rem;
            }
            h2{
                font-family: "Saira";
                color: rgba(243, 255, 228, 1);
                text-align: center;
                font-size: 1.5rem;
                font-weight: 400;
                margin-bottom: .2em;
            }
            p{
                font-family: "Open Sans";
               font-size:0.8rem;
               line-height:26px;
               opacity:70%;
               letter-spacing: 0.09em;
               color:white;
               text-align:center;
            }
            .btnGame{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            a{
                display: inline-block;
                padding: 1em 3em;
                background: linear-gradient(75.83deg, #00FFDD -14.03%, #72FFB9 -8.25%, #41F9FF 77.47%);
                border-radius: 10px;
                margin-top: 3em;
                text-decoration: none;
                text-transform: uppercase;
                font-family: "Open sans";
                font-weight: 800;
                transition: all 200ms ease;
                &:hover{
                    box-shadow: 0px 0px 20px 1px #72FFB9;
                    transform: scale(.9);
                }
            }
        }
        .cards{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;
            gap: 2em;
            margin-top: 6em;
            .card{
                height: 24em;
                width: 16em;
                background: linear-gradient(182.13deg, #001AB5 1.79%, #000000 31.79%);
                box-shadow: 0px 0px 111.644px rgba(0, 0, 0, 0.08);
                padding: .5em;
                border-radius: 20px;
                img{
                    border-radius: 10px;
                    width:100%;
                }
                h3{
                    text-align: center;
                    font-family: "Open Sans";
                    color: #fff;
                    margin: .5em 0;
                    font-weight:400;
                    letter-spacing: 0.09em;
                    font-size:1em;
                }
                p{
                    font-family: "Open sans";
                    color: #fff;
                    line-height: 1.8em;
                    text-align: center;
                    padding: 0 1em;
                    opacity: .5;
                    font-size:.8em;
                }
                
            }
        }
    }
    
    

}