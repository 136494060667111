.boidcolony{
    background-image: url("../../../public/boidcolony_bg.png");
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    width:100vw;
}



.bg-sections{
    background-image: url("../../../public/bgSections.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 999;
    #colony{
       height: 80vh;
        width: 100%;
        display: flex;
        align-items: center;
        .contenedorColony{
            width: 60%;
            margin: auto;
            text-align: center;
            h2{
                font-family: "Saira";
                font-weight: 500;
                font-size: 3rem;
                color: #F3FFE4;
                margin-bottom: 1em;
            }
            p{
                font-family: "Open Sans";
                margin: 0 auto;
                color: #F3FFE4;    
                width: 70%;
                line-height: 1.7em;
                margin-bottom: 1em; 
            }
        }
    }
    
    #ark{
        height: 80vh;
        width: 100%;
        display: flex;
        justify-content: center;
        .contenedorArk{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10em;
            width: 70%;
            .arkImg{
                width: 40%;
                img{
                    width:100%;
                }
            }
            .arkText{
                width: 60%;
                h2{
                    font-family: "Saira";
                    font-size: 3rem;
                    color: #F3FFE4;
                    font-weight: 500;
                }
                .contenedorArkText{
                    border-left: 5px solid #39FDFD;
                    padding-left: 2em;
                    p{
                        font-family: "Open Sans";
                        margin: 2em 0 2em;
                        color: #F3FFE4;  
                        line-height: 1.7em; 
                    }
                }
                
            }
        }
    }
    
    
    #planets{
        height: 80vh;
        width: 100%;
        display: flex;
        justify-content: center;
        .contenedorPlanets{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10em;
            width: 70%;
            .planetsImg{
                width: 40%;
                img{
                    width:100%;
                }
            }
            .planetsText{
                width: 60%;
                text-align: right;
                h2{
                    font-family: "Saira";
                    font-size: 3rem;
                    color: #F3FFE4;
                    font-weight: 500;
                }
                .contenedorPlanetsText{
                    border-right: 5px solid #39FDFD;
                    padding-right: 2em;
                    p{
                        font-family: "Open Sans";
                        margin: 2em 0 2em;
                        color: #F3FFE4;  
                        line-height: 1.7em; 
                    }
                }
                
            }
        }
    }
    
    #crystal{
        height: 80vh;
        width: 100%;
        display: flex;
        justify-content: center;

        .contenedorCrystal{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10em;
            width: 70%;
            .crystalImg{
                width: 30%;
                img{
                    width:100%;
                }
            }
            .crystalText{
                width: 70%;
                h2{
                    font-family: "Saira";
                    font-size: 3rem;
                    color: #F3FFE4;
                    font-weight: 500;
                }
                p{
                    font-family: "Open Sans";
                    margin: 2em 0 2em;
                    color: #F3FFE4;  
                    line-height: 1.7em; 
                }
             
            }
        }
    }
}


//TABLET

@media (max-width: 1000px) {
    .boidcolony{
        background-image: url("../../../public/boidcolony_bg.png");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        width:100vw;
    }
    
    
    
    .bg-sections{
        background-image: url("../../../public/bgSections.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 999;
        #colony{
           height: 60vh;
            width: 100%;
            display: flex;
            align-items: center;
            .contenedorColony{
                width: 100%;
                margin: auto;
                text-align: center;
                h2{
                    font-family: "Saira";
                    font-weight: 500;
                    font-size: 3rem;
                    color: #F3FFE4;
                    margin-bottom: 1em;
                }
                p{
                    font-family: "Open Sans";
                    margin: 0 auto;
                    color: #F3FFE4;    
                    width: 70%;
                    line-height: 1.7em;
                    margin-bottom: 1em; 
                }
            }
        }
        
        #ark{
            height: 60vh;
            width: 100%;
            display: flex;
            justify-content: center;
            .contenedorArk{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2em;
                width: 70%;
                .arkImg{
                    width: 40%;
                    img{
                        width:100%;
                    }
                }
                .arkText{
                    width: 60%;
                    h2{
                        font-family: "Saira";
                        font-size: 3rem;
                        color: #F3FFE4;
                        font-weight: 500;
                    }
                    .contenedorArkText{
                        border-left: 5px solid #39FDFD;
                        padding-left: 2em;
                        p{
                            font-family: "Open Sans";
                            margin: 2em 0 2em;
                            color: #F3FFE4;  
                            line-height: 1.7em; 
                        }
                    }
                    
                }
            }
        }
        
        
        #planets{
            height: 60vh;
            width: 100%;
            display: flex;
            justify-content: center;
            .contenedorPlanets{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 3em;
                width: 70%;
                .planetsImg{
                    width: 50%;
                    img{
                        width:100%;
                    }
                }
                .planetsText{
                    width: 70%;
                    text-align: right;
                    h2{
                        font-family: "Saira";
                        font-size: 3rem;
                        color: #F3FFE4;
                        font-weight: 500;
                    }
                    .contenedorPlanetsText{
                        border-right: 5px solid #39FDFD;
                        padding-right: 2em;
                        p{
                            font-family: "Open Sans";
                            margin: 2em 0 2em;
                            color: #F3FFE4;  
                            line-height: 1.7em; 
                        }
                    }
                    
                }
            }
        }
        
        #crystal{
            height: 70vh;
            width: 100%;
            display: flex;
            justify-content: center;
    
            .contenedorCrystal{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2em;
                width: 70%;
                .crystalImg{
                    width: 50%;
                    img{
                        width:200%;
                    }
                }
                .crystalText{
                    width: 100%;
                    h2{
                        font-family: "Saira";
                        font-size: 3rem;
                        color: #F3FFE4;
                        font-weight: 500;
                    }
                    p{
                        font-family: "Open Sans";
                        margin: 2em 0 2em;
                        color: #F3FFE4;  
                        line-height: 1.7em; 
                    }
                 
                }
            }
        }
    }
}



//MOBILE

@media (max-width: 535px) {
    .boidcolony{
        background-image: url("../../../public/boidcolony_bg.png");
        background-repeat: no-repeat;
        background-size:cover;
        background-position: center;
        width:100vw;

        &_container{
            height:100vh;
            justify-content: flex-start;
            padding-top:15em;

            img{
                width:100%;
            }
        }
    }
    
    
    
    .bg-sections{
        background-image: url("../../../public/bgSections.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 999;
        
        #colony{
           height: auto;
            width: 100%;
            display: flex;
            align-items: center;
            .contenedorColony{
                width: 100%;
                margin: auto;
                text-align: center;
                h2{
                    font-family: "Saira";
                    font-weight: 500;
                    font-size: 3rem;
                    color: #F3FFE4;
                    margin-bottom: 1em;
                }
                p{
                    font-family: "Open Sans";
                    margin: 0 auto;
                    color: #F3FFE4;    
                    width: 70%;
                    line-height: 1.7em;
                    margin-bottom: 1em; 
                }
            }
        }
        
        #ark{
            height: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 8em 0;
            .contenedorArk{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 2em;
                width: 70%;
                .arkImg{
                    width: 100%;
                    img{
                        width:100%;
                    }
                }
                .arkText{
                    width: 100%;
                    h2{
                        font-family: "Saira";
                        font-size: 3rem;
                        color: #F3FFE4;
                        font-weight: 500;
                    }
                    .contenedorArkText{
                        border-left: 5px solid #39FDFD;
                        padding-left: 2em;
                        p{
                            font-family: "Open Sans";
                            margin: 2em 0 2em;
                            color: #F3FFE4;  
                            line-height: 1.7em; 
                        }
                    }
                    
                }
            }
        }
        
        
        #planets{
            height: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 8em 0;
            .contenedorPlanets{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column-reverse;
                gap: 3em;
                width: 70%;
                .planetsImg{
                    width: 100%;
                    img{
                        width:100%;
                    }
                }
                .planetsText{
                    width: 100%;
                    text-align: right;
                    h2{
                        font-family: "Saira";
                        font-size: 3rem;
                        color: #F3FFE4;
                        font-weight: 500;
                    }
                    .contenedorPlanetsText{
                        border-right: 5px solid #39FDFD;
                        padding-right: 2em;
                        p{
                            font-family: "Open Sans";
                            margin: 2em 0 2em;
                            color: #F3FFE4;  
                            line-height: 1.7em; 
                        }
                    }
                    
                }
            }
        }
        
        #crystal{
            height: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 8em 0;
    
            .contenedorCrystal{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 2em;
                width: 70%;
                .crystalImg{
                    width: 100%;
                    img{
                        width:100%;
                    }
                }
                .crystalText{
                    width: 100%;
                    h2{
                        font-family: "Saira";
                        font-size: 3rem;
                        color: #F3FFE4;
                        font-weight: 500;
                    }
                    p{
                        font-family: "Open Sans";
                        margin: 2em 0 2em;
                        color: #F3FFE4;  
                        line-height: 1.7em; 
                    }
                 
                }
            }
        }
    }


    }