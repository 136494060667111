body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Saira', 'Open Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#000313;
  overflow-x:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
  margin:0;
  padding:0;
  border:0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  }

  
  a{
    text-decoration: none;
    color: inherit;
  }

  a:visited{
    color:#0e185f;
  }



  /* ULTRAWIDE */

  @media (min-width: 2560px) {
  .black-bars{
    margin: 0 10% !important;
  }

  nav{
    width:80% !important;
  }


  .header{
    background-position: bottom !important;
    background-size:cover !important;
    height:90em !important;
  }

  .whatis{
    margin-top:-20%!important;
  }


  .universe-title{
    background-position: bottom !important;
  }

  #theGame .cards .card{
    height:100% !important;
  }

  .slide-item{
    height:25em !important;
  }


  .boidvoid_header{
    width:100% !important;
    margin:0 !important;
  }

  .boidvoid_button-container{
    width:100% !important;
  }
}